<template>
  <div>
    <header-admin-pages
            title="Комментарии новостей"
            :counter="getNewsComments.length"
            :button="headerButton"/>

    <select class="input blue news__select" v-model="selectedNews">
      <option :value="null">Все</option>
      <option v-for="news in getNews" :value="news.id" :key="`newsSelect${news.id}`">
        {{ news.title }}
      </option>
    </select>

    <table>
      <tr>
        <td>Заголовок</td>
        <td>Автор</td>
        <td>Статус</td>
        <td>Дата</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="newsComment in getNewsComments" :key="`newsComment${newsComment.id}`">
        <td>{{ newsComment.news_model.title }}</td>
        <td>{{ newsComment.author_model.full_name }}</td>
        <td>{{ newsComment.status_model.title }}</td>
        <td>{{ getFormattedDate(newsComment.create_date) }}</td>
        <td class="drop-icon">
          <drop-list @clickItem="clickItem"
                     :id-item="newsComment.id"
                     :edit-link="`/admin/news_comments/${newsComment.news_model.id}/edit/${newsComment.id}`"/>
        </td>
      </tr>
    </table>
    <pagination class="pagination__block"
                :per-page="getAdminNewsCommentPagination.limit"
                :current="getAdminNewsCommentPagination.offset / getAdminNewsCommentPagination.limit + 1"
                :total="getAdminNewsCommentPagination.total"
                @page-changed="getPage"/>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
import formatDate from '@/mixins/formatDate'
import Pagination from '@/components/Pagination'

export default {
  name: 'NewsComments',

  data () {
    return {
      selectedNews: null
    }
  },

  watch: {
    selectedNews () {
      if (this.selectedNews) {
        this.$store.dispatch('getNewsComments', this.selectedNews)
      } else {
        this.$store.dispatch('getAllAdminNewsComments')
      }
    }
  },

  created () {
    this.$store.commit('setNewsComments', [])
    this.$store.dispatch('getAllAdminNewsComments')
    this.$store.commit('setAdminNewsPaginationLimit', null)
    this.$store.dispatch('getNews')
  },

  beforeDestroy () {
    this.$store.commit('setAdminNewsPaginationLimit', 9)
  },

  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить комментарий?')
        if (consent) {
          this.$store.dispatch('deleteNewsComment', {
            comment: value.id,
            news: this.selectedNews
          })
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailMembers', value.id)
      }
    },

    getPage (page) {
      this.$store.commit('setAdminNewsCommentsPaginationOffset', (page - 1) * this.getAdminNewsCommentPagination.limit)
      this.$store.dispatch('getAllAdminNewsComments')
    }
  },

  computed: {
    getNews () {
      return this.$store.getters.getNews
    },

    getNewsComments () {
      return this.$store.getters.getNewsComments
    },

    getAdminNewsCommentPagination () {
      return this.$store.getters.getAdminNewsCommentPagination
    },

    headerButton () {
      return {
        title: 'Добавить комментарий',
        link: `/admin/news_comments/${this.selectedNews}/create`
      }
    }
  },

  components: {
    Pagination,
    DropList,
    HeaderAdminPages
  },

  mixins: [formatDate]
}
</script>

<style scoped lang="scss">
  .news__select {
    margin-top: 1rem;
  }

  table{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
